var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-box-one"},[(_vm.isLoading)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"image","height":"268"}})],1):_c('div',{class:['overflow-hidden p-10 bg-white', { 'rounded border': !_vm.noBorder }]},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"flex-shrink-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"position-relative"},[(_vm.discount > 0)?_c('div',{staticClass:"discount-badge"},[_vm._v(" "+_vm._s(_vm.$t("off"))+" "+_vm._s(_vm.discount)+"% ")]):_vm._e(),_c('router-link',{staticClass:"text-reset d-block lh-0 text-center",attrs:{"to":{
              name: 'ProductDetails',
              params: { slug: _vm.productDetails.slug },
            }}},[_c('img',{staticClass:"img-fit h-104px border-r-13",attrs:{"src":_vm.addResourceImage(_vm.productDetails.thumbnail_image),"alt":_vm.productDetails.name},on:{"error":function($event){return _vm.imageFallback($event)}}})])],1)]),_c('v-col',{staticClass:"minw-0 flex-shrink-0",attrs:{"cols":12}},[_c('div',{staticClass:"d-flex flex-column pt-2"},[_c('div',{staticClass:"fs-20 mb-2 font-price"},[(_vm.productDetails.base_price > _vm.productDetails.base_discounted_price)?[_c('del',{staticClass:"opacity-40"},[_vm._v(_vm._s(_vm.format_price(_vm.productDetails.base_price)))]),_c('span',{staticClass:"fw-500"},[_vm._v(_vm._s(_vm.format_price(_vm.productDetails.base_discounted_price)))])]:[_c('span',{staticClass:"fw-500"},[_vm._v(_vm._s(_vm.format_price(_vm.productDetails.base_discounted_price)))])]],2),_c('h5',{staticClass:"fw-400 mb-2 lh-1-6 fs-12 text-truncate-2 h-48px"},[_c('router-link',{staticClass:"text-reset",attrs:{"to":{
                name: 'ProductDetails',
                params: { slug: _vm.productDetails.slug },
              }}},[_vm._v(_vm._s(_vm.productDetails.name))])],1),_c('div',{staticClass:"vi-line"}),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"flex-grow-1 me-1 mt-2"},[(_vm.productDetails.stock)?[_c('button',{staticClass:"text-reset lh-1 align-center d-flex",on:{"click":function($event){return _vm.showAddToCartDialog({
                      status: true,
                      slug: _vm.productDetails.slug,
                    })}}},[_c('i',{staticClass:"la la-shopping-cart fs-28 ts-05 me-1",staticStyle:{"color":"#CF9610"}}),_c('span',{staticClass:"fw-700 fs-13",staticStyle:{"color":"#CF9610"}},[_vm._v(_vm._s(_vm.$t("buy_now")))])])]:[_c('span',{staticClass:"fw-700 fs-13 opacity-60"},[_vm._v(_vm._s(_vm.$t("out_of_stock")))])]],2),_c('div',{staticClass:"mt-2 w-30px h-30px"},[(_vm.isThisWishlisted(_vm.productDetails.id))?[_c('button',{staticClass:"text-red pt-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeFromWishlist(_vm.productDetails.id)}}},[_c('i',{staticClass:"la la-heart ts-02 fs-24"})])]:[_c('button',{staticClass:"text-red btn-heart mt-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.addNewWishlist(_vm.productDetails.id)}}},[_c('i',{staticClass:"la la-heart heart-bg-white fs-16 ts-02"})])]],2)])])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }